<template>
  <ActionModal
    v-if="isModalShown"
    :width="'500px'"
    :height="'250px'"
    :isModalShown="isModalShown"
  >
    <div class="modal-header">
      <div class="success-icon"></div>
    </div>
    <div class="modal-content">
      <div class="modal-title">Thank you. Your subscription has been created successfully!</div>
      <ActionButton
        :width="'250px'"
        :height="'54px'"
        :text="'OK'"
        @click="$emit('proceedAfterSubscriptionCreation')"
      />
    </div>
  </ActionModal>
</template>

<script>
import ActionModal from '../../common/ActionModal.vue'
import ActionButton from '../../common/ActionButton.vue'
export default {
  props: [
    'isModalShown',
  ],
  components: {
    ActionModal,
    ActionButton,
  }
}
</script>

<style lang="scss" scoped="true">
@import '../../../assets/styleVars.scss';

.modal-header {
  width: calc(100% - 80px);
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content:center;

  .modal-title {
    font-size: $fontSizeModalTitle;
    color: greenyellow;
  }
  .success-icon {
    background-image: url('../../../assets/icons/success-icon.svg');
    background-repeat: no-repeat;
    background-position: center;
    width: 54px;
    height: 54px;
  }
  .close-modal-btn {
    width: 24px;
    height: 24px;
    background-size: 14px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../../../assets/icons/close-icon.svg');
    cursor: pointer;
  }
}

.modal-content {
  margin-top: 24px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;

  .modal-title {
    color: green
  }
}

</style>
