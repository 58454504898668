<template>
  <div class="header">
    <div class="header-row">
      <div class="go-back-arrow" @click="goBack"></div>
      <div class="logo"></div>
    </div>
  </div>
  <div class="loading-container" v-if="isLoading">
    <div class="spinner"></div>
  </div>
  <div class="subscriptions-container-wrapper">
    <div class="subscriptions-container">
      <div class="subscription-variants">
        <ErrorActionModal
          :isModalShown="isModalErrorShown"
          :pipelineErrorMessage="errorMessage"
          @hideErrorModal="hideErrorModal"
        />
        <SubscriptionActionModal
          :isModalShown="isSubscriptionModalShown"
          @proceedAfterSubscriptionCreation="proceedAfterSubscriptionCreation"
        />
        <div class="error-container">{{errorMessage}}</div>
        <div
          class="subscription-item"
          :class="{'selected-subscription': selectedPrice === item.price_id}"
          v-for="item in subscriptionVariants"
          :key="item.price_id"
          @click="selectSubscription(item.price_id)"
        >
          <div
            class="radio-btn"
            :class="{'active-radio-btn': selectedPrice === item.price_id}"
          >
          </div>
          <div class="subscription-info">
            <div class="subscription-title">
              {{'$' + item.unit_amount / 100 + ' ' + item.name}}
            </div>
            <div class="subscription-description">
              {{getSubscriptionDescription(item.name)}}
            </div>
          </div>
        </div>
      </div>
      <Card
        v-if="defaultCard"
        :key="defaultCard.id"
        :cardId="defaultCard.id"
        :last4="defaultCard.last4"
        :userDefaultCard="currentUser.subscription.default_payment_method"
        :cardExpirationMonth="defaultCard.exp_month"
        :cardExpirationYear="defaultCard.exp_year"
        :isActionsAvailable="false"
      />
      <div id="card-element" v-if="!defaultCard">
        <!-- stripe UI will be loaded here -->
      </div>
      <ActionButton
        :class="{'minified-margin': defaultCard}"
        :width="'calc(100% - 20px)'"
        :height="'54px'"
        :minHeight="'30px'"
        :text="'Pay and subscribe'"
        @click="handlePaymentSubmit"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import Card from '../../common/cards/Card.vue'
import ActionButton from '../../common/ActionButton.vue'
import ErrorActionModal from '../../common/ErrorActionModal.vue'
import SubscriptionActionModal  from './SubscriptionActionModal.vue'

import * as subscriptionsApi from '../../../api/subscriptionsApi'
import { UserSubscriptionStatus } from '../../../utils/constants'

export default {
  data() {
    return {
      stripe: null,
      elements: null,
      card: null,
      defaultCard: null,
      subscriptionVariants: [],
      selectedPrice: '',
      errorMessage: '',
      isLoading: true,
      isSubscriptionsLoading: true,
      isModalErrorShown: false,
      isSubscriptionModalShown: false,
    }
  },
  components: {
    ActionButton,
    ErrorActionModal,
    SubscriptionActionModal,
    Card,
  },
  async created() {
    try {
      await this.fetchInitialData()
    } catch (error) {
      this.errorMessage = 'Error occured while loading payments data.'
    } finally {
      this.isLoading = false
    }
  },
  computed: {
    ...mapState([
      'currentUser',
      'cardsList',
    ]),
  },
  methods: {
    ...mapActions([
      'updateUserLocally',
      'getCardsList',
    ]),
    hideErrorModal() {
      this.isModalErrorShown = false;
    },
    goBack() {
      this.$router.go(-1)
    },
    async getSubscriptionVariants() {
      this.subscriptionVariants = await subscriptionsApi.getSubscriptionsVariants()
    },
    async setDefaultCardLocally() {
      if (!this.cardsList.length) {
        await this.getCardsList()
      }

      const { subscription: { default_payment_method } } = this.currentUser
      this.defaultCard = this.cardsList.find(card => card.id === default_payment_method)
    },
    handleStripeErrors(error) {
      if (error) {
        this.errorMessage = error.message
      } else {
        this.errorMessage = ''
      }
    },
    async connectStripe() {
      this.stripe = await Stripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY)

      if (this.defaultCard) return

      this.elements = this.stripe.elements()

      const cardStyle = {
        base: {
          color: "black",
          fontFamily: 'Rubik, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "18px",
          "::placeholder": {
            color: "gray"
          }
        },
        invalid: {
          fontFamily: 'Rubik, sans-serif',
          color: "#fa755a",
          iconColor: "#fa755a"
        }
      }

      this.card = this.elements.create('card', { style: cardStyle })
      this.card.on('change', event => {
        const { error } = event
        this.handleStripeErrors(error)
      })
      this.card.mount('#card-element')
    },
    async fetchInitialData() {
      await this.getSubscriptionVariants()

      if (this.currentUser.subscription.default_payment_method) {
        await this.setDefaultCardLocally()
      }

      await this.connectStripe()
    },
    getSubscriptionDescription(subscriptionName) {
      if (subscriptionName === 'Year plan') {
        return 'You will receive a full package of services for a year'
      }

      if (subscriptionName === 'Month plan') {
        return 'You will receive a full package of services for a month'
      }

      if (subscriptionName === 'Daily plan') {
        return 'You will receive a full package of services for a day'
      }
    },
    selectSubscription(selectedPrice) {
      this.selectedPrice = selectedPrice
    },
    async proceedAfterSubscriptionCreation(subscription) {
      const user_update = { subscription }

      await this.updateUserLocally(user_update)
      this.$router.push({ name: 'Clients' })
    },
    checkSubscriptionStatus() {
      setTimeout(async () => {
        const { subscription } = await subscriptionsApi.getUserSubscription()

        if (subscription.status !== UserSubscriptionStatus.Active) {
          this.checkSubscriptionStatus()
          return
        }

        await this.proceedAfterSubscriptionCreation(subscription)
      }, 2500)
    },
    async handlePaymentSubmit() {
      this.isLoading = true

      try {
        const payload = {
          subscription_creation_request: {
            price_id: this.selectedPrice,
          },
        }
        const data = await subscriptionsApi.createStripeSubscription(payload)
        const { client_secret } = data
        const payment_method = this.defaultCard ? this.defaultCard.id : { card: this.card }
        const result = await this.stripe.confirmCardPayment(
          client_secret,
          { payment_method }
        )

        if (result.error) {
          this.handleStripeErrors(result.error)
          this.isLoading = false
          return
        }

        subscriptionsApi.sendPurchaseNotification()

        this.checkSubscriptionStatus()
      } catch (error) {
        this.errorMessage = 'Something went wrong. Please, try again.'
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '../../../assets/styleVars.scss';

  .header {
    width: 100%;
    height: 64px;
    background-color: #13141F;
    display: flex;
    position: relative;
    align-items: center;
    align-content: space-around;

    .header-row {
      margin-left: 55px;
      width: 50%;
      display: flex;
      position: relative;
      justify-content: space-between;
      align-items: center;

      .go-back-arrow {
        background-image: url('../../../assets/icons/go-back-arrow.svg');
        width: 24px;
        height: 24px;
        cursor: pointer;
        position: relative;
      }

      .logo {
        width: 100px;
        height: 55px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 95px 44px;
        background-image: url('../../../assets/images/simple_sales_logo_white.png');
      }
    }
  }

  .loading-container {
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    z-index: 999999;
    justify-content: center;
    cursor: wait;
    background-color: $whiteColor;

    .spinner {
      margin-top: 200px;
      border: 5px solid $whiteColor;
      border-radius: 50%;
      border-top: 5px solid $appActionColor;
      width:45px;
      height:45px;
      animation: spin 2s linear infinite;
    }
    
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  }

  .subscriptions-container-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .subscriptions-container {
      width: 600px;
      height: 100%;
      flex-direction: column;
      display: flex;
      justify-content: center;
      align-items: center;

      .card-wrapper {
        width: 100%;
        height: 40px;
      }

      .error-container {
        width: calc(100% - 20px);
        height: 20px;
        color: $redWarningColor;
        font-size: $fontSizeStandart;
      }

      .subscription-variants {
        width: calc(100% - 20px);
        height: 260px;
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        .subscription-item {
          width: 100%;
          height: 125px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-radius: 4px;

          &.selected-subscription {
            border: 1px solid $appActionColor;
          }

          .radio-btn {
            width: 20px;
            height: 20px;
            margin: 0 31px;
            display: flex;
            justify-content: center;
            cursor: pointer;
            background-size: 20px;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url('../../../assets/icons/radio-btn-off-icon.svg');
          }

          .active-radio-btn {
            background-image: url('../../../assets/icons/radio-btn-on-icon.svg');
          }

          .subscription-info {
            height: calc(100% - 20px);
            width: 450px;
            margin-right: 31px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;

            .subscription-title {
              font-size: 24px;
            }

            .subscription-description {
              margin-top: 5px;
              font-size: 16px;
              color: $fontColorGray;
            }
          }
        }
      }

      .user-payment-card {
        width: calc(100% - 20px);
        margin-top: 30px;
      }

      #card-element {
        width: calc(100% - 20px);
        margin-top: 80px;
        box-sizing: border-box;
        background: $whiteColor;
        border-radius: 4px;
      }

      .action-button {
        margin-top: 60px;
      }

      .minified-margin {
        margin-top: 20px;
      }
    }
  }
  
  @media (max-width: 420px) {
  .loading-container {
    width: 100%;
    height: 100%;
    margin-top: 0px;
    position: absolute;
    z-index: 999999;
    background: $whiteColor;

    .spinner {
      margin-top: 215px;
    }
  }

    .subscriptions-container {
      width: 350px !important;

      #card-element {
        width: calc(100% - 20px) !important;
      }

      .subscription-variants {
        .subscription-item {
          .radio-btn {
            width: 36px !important;
          }
        }
      }
    }

  }
</style>
